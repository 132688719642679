export function validaDni(dni) {
  if (dni != "admin"){
    var numero, letr, letra;
    var expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;

    //dni = dni.toUpperCase();

    if (expresion_regular_dni.test(dni) === true) {
      numero = dni.substr(0, dni.length - 1);
      numero = numero.replace('X', 0);
      numero = numero.replace('Y', 1);
      numero = numero.replace('Z', 2);
      letr = dni.substr(dni.length - 1, 1);
      numero = numero % 23;
      letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
      letra = letra.substring(numero, numero + 1);
      if (letra != letr) {
        return 'Formato del DNI/NIE incorrecto.';
      }
      else return true;
    } else {
      return 'Formato del DNI/NIE incorrecto.';
    }
  }
  else return true;
}

export function validaPwd(dni, pwd, r) {
  return axios({
    method: 'post',
    url: `${process.env.VUE_APP_API_URL}/checkPwd`,
    data: { dni, pwd },
    headers: { 'Content-Type': 'application/json' }
  }).then((result) => {
    if(r) localStorage.setItem("token", JSON.stringify(result.data));
    else sessionStorage.setItem("token", JSON.stringify(result.data));
    return true;
  }).catch(function (error) {
    console.log(error);
    return false;
  });
}