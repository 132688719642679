<template>
  <div class="text-center">
    <qrcode-vue
      :value="value"
      :size="size"
      class="mx-auto"
      style="width: 300px; height: 300px;"
      level="H"
      background="#bbdefb"
      foreground="#0D47A1"
    />
    <v-btn class="pa-5 mt-5">
      {{ value.substring(0,8) }}
    </v-btn>
  </div>
</template>

<script>
import { uuid } from "vue-uuid";
import QrcodeVue from "qrcode.vue";
import axios from "axios";
export default {
  name: "CodeView",
  data() {
    return {
      value: uuid.v4(),
      size: 300,
      intervalo: null,
    };
  },
  metaInfo(){
    return { title : "Código" }
  },
  methods: {
    setCode(codigo) {
      axios({
        method: "post",
        url: `${process.env.VUE_APP_API_URL}/setCode`,
        data: { codigo },
        headers: { 'Content-Type': 'application/json' }
      })
        .then((result) => {
          console.log(result);
          return true;
        })
        .catch(function (error) {
          console.error(error);
          return false;
        });
    },
  },
  beforeMount() {
    this.setCode(this.value);
  },
  mounted(){
    this.intervalo = setInterval(() => {
      this.value = uuid.v4();
      this.setCode(this.value);
    }, 60*1000*5);
  },
  beforeDestroy(){
    clearInterval(this.intervalo)
  },
  components: {
    QrcodeVue,
  },
};
</script>