<template>
  <div class="text-center">
    <v-banner outlined shaped class="mb-5 text-center" style="background-color: #90CAF9">
      Haz clic en el botón redondo para empezar/finalizar una jornada. <br/>En caso de tener una cámara en el dispositivo, escanea el código qr facilitado por la empresa.<br/>
      En caso de no tener cámara, aparecerá un campo para teclear el código a mano.
    </v-banner>
    <v-snackbar
      v-model="snackbar"
      timeout="2500"
      :color="sbColor"
      top
      class="mt-16"
    >
      {{ msg }}
    </v-snackbar>
    <v-btn
      fab
      :color="btnColor"
      :width="btnWidth"
      :height="btnWidth"
      elevation="24"
      ref="startScan"
      @click="scan"
      :class="{ 'd-none': scanning }"
      class="text-h5 font-weight-black"
    >
      <v-progress-circular
        indeterminate
        color="white"
        class="ms-2"
        :width="15"
        :size="150"
        :style="cargando ? '' : 'display:none'"
      ></v-progress-circular>
      <div :style="cargando ? 'display:none' : ''">
        {{ trabajando ? "Finalizar jornada" : "Empezar jornada" }}
      </div>
    </v-btn>
    <div class="d-flex">
      <v-alert
        type="info"
        elevation="2"
        dense
        outlined
        class="flex-grow-1 pa-4"
        :class="{ 'd-none': !camError || !scanning }"
      >
        {{ camErrorMsg }}
      </v-alert>
    </div>
    <v-card class="mx-auto" :class="{ 'd-none': !camError || !scanning }">
      <v-text-field 
      v-model="codigo"
      class="px-4 pt-6"
      label="Teclea el código aquí"
      > </v-text-field>
      <v-btn
        color="info"
        @click="setStatus(codigo, null)"
        class="my-2"
        elevation="12"
      >
        Comprobar código
      </v-btn>
    </v-card>
    <div class="mx-auto" :class="{ 'd-none': !scanning }">
      <video id="camera"></video>
    </div>
    <v-btn
      color="error"
      @click="logout"
      :width="btnWidth"
      class="mt-5"
      elevation="24"
    >
      Cerrar Sesión
    </v-btn>
  </div>
</template>

<script>
import jwt_decode from "jwt-decode";
import QrScanner from "qr-scanner";

export default {
  name: "HomeView",
  data() {
    return {
      btnColor: '',
      msg: '',
      codigo: '',
      cargando: true,
      snackbar: false,
      sbColor: "success",
      scanning: false,
      trabajando: false,
      camErrorMsg: "",
      camError: false,
    };
  },
  metaInfo() {
    return { title: "Inicio" };
  },
  methods: {
    scan: function () {
      this.scanning = true;
      const video = document.getElementById("camera");
      const self = this;

      const scanner = new QrScanner(video, (c) => self.setStatus(c, scanner), {
        onDecodeError: (error) => {
          console.log(error);
        },
        highlightScanRegion: true,
        highlightCodeOutline: true,
      });

      navigator.mediaDevices
        .getUserMedia({
          video: true,
        })
        .then(function () {
          scanner.start();
        })
        .catch(function (error) {
          video.style.height = 0;
          if (error.message.includes("Requested device not found")) {
            self.camErrorMsg =
              "No se han encontrado cámaras en el dispositivo, teclea el código a mano.";
            self.camError = true;
          } else if (error.message.includes("Permission denied")) {
            self.camErrorMsg =
              "Permite a tu navegador acceder a la cámara del dispositivo o teclea el código a mano.";
            self.camError = true;
          } else {
            document.write(error.message);
            return;
          }
        });

      scanner.setInversionMode("both");

      window.scanner = scanner;
    },
    setStatus: function (c, s) {
      if (s != null) s.stop();
      var dni;
      if (!!localStorage.getItem("token"))
        dni = jwt_decode(localStorage.getItem("token")).dni;
      else dni = jwt_decode(sessionStorage.getItem("token")).dni;
      const self = this;
      axios({
        method: "post",
        url: `${process.env.VUE_APP_API_URL}/setStatus`,
        data: { codigo: c, dni },
        headers: { "Content-Type": "application/json" },
      })
        .then((result) => {
          self.msg = "Estado actualizado correctamente.";
          self.sbColor = "success";
          self.snackbar = false;
          self.snackbar = true;
          self.scanning = false;
          self.trabajando = !self.trabajando;
          if(self.trabajando) self.btnColor = "error";
          else self.btnColor = "info";
        })
        .catch(function (error) {
          console.log(error)
          self.msg = "Error al procesar el código. Vuelve a intentarlo.";
          self.sbColor = "error";
          self.snackbar = false;
          self.snackbar = true;
          return false;
        });
    },
    getStatus: function () {
      var dni;
      if (!!localStorage.getItem("token"))
        dni = jwt_decode(localStorage.getItem("token")).dni;
      else dni = jwt_decode(sessionStorage.getItem("token")).dni;
      axios({
        method: "post",
        url: `${process.env.VUE_APP_API_URL}/getStatus`,
        data: { dni },
        headers: { "Content-Type": "application/json" },
      })
        .then((result) => {
          if (result.data == "0") {
            this.trabajando = false;
            this.btnColor = "primary";
            }
          else if (result.data == "1") {
            this.trabajando = true;
            this.btnColor = "error";
          }
          this.cargando = false;
          return;
        })
        .catch(function (error) {
          console.log(error);
          return false;
        });
    },
    logout: function () {
      localStorage.clear();
      sessionStorage.clear();
      this.$router.push({ name: "login" });
    },
  },
  beforeMount() {
    this.getStatus();
  },
  computed: {
    btnWidth() {
      if (window.innerWidth < 300) {
        return window.innerWidth - 60;
      }
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 300;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
  },
};
</script>