<template>
  <div>
    <v-banner outlined shaped class="mb-5 text-center" style="background-color: #90CAF9">
      Aquí puedes añadir usuarios, editar su información, o borrarlos. <br/>
      Al editar un usuario, el campo "Nueva contraseña" estará vacío. <br/>
      No es obligatorio cambiarle la contraseña, deja el campo vacío y la contrasñea se quedará como estaba anteriormente.<br/>
    </v-banner>
    <v-snackbar
      v-model="snackbar"
      timeout="2000"
      :color="sbColor"
      top
      class="mt-16"
    >
      {{ msg }}
    </v-snackbar>
    <v-data-table
      :headers="headers"
      :items="users"
      sort-by="dni"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Usuarios</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="align-center" @click="addItem">
            Añadir usuario
          </v-btn>
          <v-dialog v-model="dialogEdit" max-width="500px">
            <v-card>
              <v-form ref="editForm" v-model="validEdit" lazy-validation>
                <v-card-title>
                  <span class="text-h5">Editar usuario</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.dni"
                          :rules="dniRules"
                          label="DNI/NIE"
                          autocomplete="new-password"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          type="password"
                          v-model="newPwd"
                          label="Nueva contraseña"
                          autocomplete="new-password"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.nombre"
                          :rules="nspRules"
                          label="Nombre"
                          autocomplete="new-password"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.apellidos"
                          :rules="nspRules"
                          label="Apellidos"
                          autocomplete="new-password"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.email"
                          counter="255"
                          label="Email"
                          autocomplete="new-password"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.tlf"
                          label="Teléfono"
                          counter="9"
                          autocomplete="new-password"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-switch
                          v-model="editedItem.vip"
                          label="Administrador"
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="editClose">
                    Cancelar
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="editSave">
                    Guardar
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogAdd" max-width="500px">
            <v-card>
              <v-form ref="addForm" v-model="validAdd" lazy-validation>
                <v-card-title>
                  <span class="text-h5">Nuevo usuario</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.dni"
                          :rules="dniRules"
                          label="DNI/NIE"
                          autocomplete="new-password"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          type="password"
                          v-model="editedItem.pwd"
                          :rules="nspRules"
                          label="Contraseña"
                          autocomplete="new-password"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.nombre"
                          :rules="nspRules"
                          label="Nombre"
                          autocomplete="new-password"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.apellidos"
                          :rules="nspRules"
                          label="Apellidos"
                          autocomplete="new-password"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.email"
                          counter="255"
                          label="Email"
                          autocomplete="new-password"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.tlf"
                          label="Teléfono"
                          counter="9"
                          autocomplete="new-password"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-switch
                          v-model="editedItem.vip"
                          label="Administrador"
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="addClose">
                    Cancelar
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="addSave">
                    Guardar
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >¿Eliminar usuario? Esta acción es irreversible.</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancelar</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >Aceptar</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Recargar </v-btn>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { validaDni } from "@/utils/rules.js";
export default {
  name: "UserTable",
  data: () => ({
    dniRules: [(v) => !!v || "Este campo es obligatorio.", (v) => validaDni(v)],
    nspRules: [(v) => !!v || "Este campo es obligatorio."],
    snackbar: false,
    msg: "",
    oldDni: "",
    newPwd: "",
    sbColor: "",
    validAdd: true,
    validEdit: true,
    dialogEdit: false,
    dialogAdd: false,
    dialogDelete: false,
    headers: [
      { text: "DNI/NIE", value: "dni", align: "start" },
      { text: "Nombre", value: "nombre" },
      { text: "Apellidos", value: "apellidos" },
      { text: "Email", value: "email" },
      { text: "Teléfono", value: "tlf" },
      { text: "", value: "actions", sortable: false },
    ],
    users: [],
    editedIndex: -1,
    editedItem: {
      dni: "",
      pwd: "",
      nombre: "",
      apellidos: "",
      email: "",
      tlf: "",
      vip: false,
    },
    defaultItem: {
      dni: "",
      pwd: "",
      nombre: "",
      apellidos: "",
      email: "",
      tlf: "",
      vip: false,
    },
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.newPwd = "";
      this.users = [];
      const self = this;
      axios({
        url: `${process.env.VUE_APP_API_URL}/getUsers`,
        headers: { "Content-Type": "application/json" },
      })
        .then((result) => {
          for (let i = 0; i < result.data.length; i++) {
            this.users.push({
              dni: result.data[i][0],
              pwd: result.data[i][1],
              nombre: result.data[i][2],
              apellidos: result.data[i][3],
              email: result.data[i][4],
              tlf: result.data[i][5],
              vip: result.data[i][6],
            });
          }
        })
        .catch(function (error) {
          self.msg = "Error al cargar la lista de usuarios. Prueba a recargar.";
          self.snackbar = false;
          self.snackbar = true;
          self.sbColor = "error";
        });
    },
    editItem(item) {
      this.oldDni = item.dni;
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogEdit = true;
    },
    addItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.vip = false;
      this.dialogAdd = true;
    },
    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      const self = this;
      console.log(this.users[this.editedIndex].dni);
      axios({
        method: "post",
        url: `${process.env.VUE_APP_API_URL}/deleteUser`,
        data: {
          dni: this.users[this.editedIndex].dni,
        },
        headers: { "Content-Type": "application/json" },
      })
        .then((result) => {
          console.log(result);
          self.msg = "Usuario eliminado correctamente.";
          self.snackbar = false;
          self.snackbar = true;
          self.sbColor = "success";
          console.log(this.editedIndex);
          this.users.splice(this.editedIndex, 1);
          return true;
        })
        .catch(function (error) {
          console.log(error);
          self.msg = "Ha ocurrido un problema al eliminar el usuario.";
          self.snackbar = false;
          self.snackbar = true;
          self.sbColor = "error";
          return false;
        });
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    addClose() {
      this.dialogAdd = false;
    },
    editClose() {
      this.newPwd = "",
      this.dialogEdit = false;
    },
    addSave() {
      if (this.$refs.addForm.validate()) {
        const self = this;
        axios({
          method: "post",
          url: `${process.env.VUE_APP_API_URL}/addUser`,
          data: {
            dni: this.editedItem.dni,
            pwd: this.editedItem.pwd,
            nombre: this.editedItem.nombre,
            apellidos: this.editedItem.apellidos,
            email: this.editedItem.email,
            tlf: this.editedItem.tlf,
            vip: this.editedItem.vip,
          },
          headers: { "Content-Type": "application/json" },
        })
          .then((result) => {
            console.log(result);
            self.msg = "Usuario creado correctamente.";
            self.snackbar = false;
            self.snackbar = true;
            this.users.push(this.editedItem);
            self.sbColor = "success";
            return true;
          })
          .catch(function (error) {
            console.log(error);
            self.msg =
              "Ya existe un usuario con ese DNI/NIE en la base de datos.";
            self.snackbar = false;
            self.snackbar = true;
            self.sbColor = "error";
            return false;
          });
        this.addClose();
      }
    },
    editSave() {
      if (this.$refs.editForm.validate()) {
        const self = this;
        axios({
          method: "post",
          url: `${process.env.VUE_APP_API_URL}/editUser`,
          data: {
            oldDni: this.oldDni,
            dni: this.editedItem.dni,
            pwd: this.newPwd,
            nombre: this.editedItem.nombre,
            apellidos: this.editedItem.apellidos,
            email: this.editedItem.email,
            tlf: this.editedItem.tlf,
            vip: this.editedItem.vip,
          },
          headers: { "Content-Type": "application/json" },
        })
          .then((result) => {
            console.log(result);
            self.msg = "Usuario editado correctamente.";
            self.snackbar = false;
            self.snackbar = true;
            Object.assign(this.users[this.editedIndex], this.editedItem);
            self.sbColor = "success";
            self.initialize();
            return true;
          })
          .catch(function (error) {
            console.log(error);
            self.msg =
              "Ya existe un usuario con ese DNI/NIE en la base de datos.";
            self.snackbar = false;
            self.snackbar = true;
            self.sbColor = "error";
            return false;
          });
        this.editClose();
      }
    },
  },
};
</script>