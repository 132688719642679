<template>
  <v-row class="align-self-start">
    <v-col cols="12">
      <v-list class="py-0">
        <v-row no-gutters>
          <v-tabs
              grow
          >
              <v-tab
              v-for="item in items"
              :key="item.title"
              link :to="item.to">
                <v-row class="pa-6 align-center justify-center">
                  <v-icon left>{{ item.icon }}</v-icon>
                  {{ item.title }}
                </v-row>
              </v-tab>
          </v-tabs>
          <v-col cols="12">
            <div class="pa-2 d-flex justify-center align-center">
              <v-btn @click="logout" color="error"> Cerrar sesión </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-list>
    </v-col>
    <v-col cols="12">
      <router-view />
    </v-col>
  </v-row>
</template>

<script>
import UserTable from "@/components/UserTable.vue";
import JornadasTable from "@/components/JornadasTable.vue";
export default {
  components: {
    UserTable,
    JornadasTable,
  },
  metaInfo() {
    return { title: "Panel de control" };
  },
  data() {
    return {
      items: [
        {
          title: "Usuarios",
          icon: "mdi-account-group-outline",
          to: "/admin/UserTable",
        },
        {
          title: "Jornadas",
          icon: "mdi-timer-outline",
          to: "/admin/JornadasTable",
        },
      ],
    };
  },
  methods: {
    logout: function () {
      localStorage.clear();
      sessionStorage.clear();
      this.$router.push({ name: "login" });
    },
  },
};
</script>