<template>
  <v-container>
    <v-menu
      ref="picker"
      v-model="picker"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="date"
          :label="'Fecha de ' + accion"
          hint="YYYY-MM-DD"
          persistent-hint
          prepend-icon="mdi-calendar"
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        no-title
        @input="picker = false"
      ></v-date-picker>
    </v-menu>
  </v-container>
</template>
<script>
export default {
  name: "datePicker",
  props: {
    accion: String,
    inicial: String,
  },
  data: function () {
    return {
      date: this.inicial,
      picker: false,
    };
  },
  watch: {
    date(val) {
      this.$emit("dateChange", this.date);
    },
  },
};
</script>