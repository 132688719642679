import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import axios from "axios";
import vuemeta from "vue-meta";

window.axios = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}`,
});

Vue.config.productionTip = false
Vue.use(vuemeta)
new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
