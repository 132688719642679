<template>
  <div class="d-flex flex-column justify-centeralign-center">
    <v-banner outlined shaped :width="formWidth" class="mb-16 text-center" style="background-color: #90CAF9">
      Teclea tu DNI/NIE y la contraseña que se te ha facilitado.<br/>
      En caso de elegir "Recordar credenciales", no tendrás que volver a iniciar sesión en este dispositivo.
    </v-banner>
    <v-card
      :width="formWidth"
      class="mx-auto"
      style="background-color: #e3f2fd"
    >
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-title> Iniciar Sesión </v-card-title>
        <v-card-text>
          <v-text-field
            label="DNI/NIE"
            prepend-icon="mdi-card-account-details"
            v-model="usuario"
            :rules="userRules"
            @input="errorMsg = ''"
          />
          <v-text-field
            label="Contraseña"
            :type="mostrarPwd ? 'text' : 'password'"
            prepend-icon="mdi-lock"
            :append-icon="mostrarPwd ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="mostrarPwd = !mostrarPwd"
            v-model="pwd"
            :rules="pwdRules"
            @input="errorMsg = ''"
          />
          <v-switch
            v-model="recordar"
            :label="`Recordar credenciales`"
          ></v-switch>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            style="background-color: #2196f3; color: white"
            class="mx-auto my-2"
            @click="validate"
            >Iniciar Sesión
            <v-progress-circular
              indeterminate
              color="white"
              class="ms-2"
              :width="2"
              :size="25"
              :style="cargando ? '' : 'display:none'"
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <br />
    <v-alert
      border="right"
      colored-border
      type="error"
      elevation="2"
      :style="this.errorMsg.length == 0 ? 'display: none' : ''"
      class="mx-auto my-5"
      :width="formWidth"
    >
      {{ errorMsg }}
    </v-alert>
  </div>
</template>
<script>
import { validaDni, validaPwd } from "@/utils/rules.js";
export default {
  name: "LoginView",
  data() {
    return {
      recordar: false,
      cargando: false,
      errorMsg: "",
      valid: true,
      mostrarPwd: false,
      usuario: "",
      userRules: [
        (v) => !!v || "Este campo es obligatorio.",
        (v) => validaDni(v),
      ],
      pwd: "",
      pwdRules: [(v) => !!v || "Este campo es obligatorio."],
    };
  },
  metaInfo() {
    return { title: "Log In" };
  },
  methods: {
    clearToken() {
      sessionStorage.clear();
      localStorage.clear();
    },
    async validate() {
      this.cargando = true;
      if (!this.$refs.form.validate()) return;
      if (await validaPwd(this.usuario, this.pwd, this.recordar)) {
        if (this.usuario == "admin") this.$router.push({ name: "admin" });
        else this.$router.push({ name: "home" });
      } else {
        this.cargando = false;
        this.errorMsg = "Datos incorrectos";
      }
    },
  },
  beforeMount() {
    this.clearToken();
  },
  computed: {
    formWidth() {
      if (window.outerWidth < 300) {
        return window.outerWidth - 20;
      }
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 300;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
  },
};
</script>