<template>
  <v-app id="app">
    <v-main>
      <v-container
        fluid
        style="height: 100%; background-color: #bbdefb"
        class="d-flex justify-center align-center"
      >
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>
<script>

export default {
  name: "App",
  components: {
  },
};
</script>
<style>
</style>
