import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../views/LoginView.vue'
import HomeView from '../views/HomeView.vue'
import CodeView from '../views/CodeView.vue'
import AdminView from '../views/AdminView.vue'
import UserTable from '../components/UserTable.vue'
import JornadasTable from '../components/JornadasTable.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { autenticado: true },

  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: { autenticado: false },
  },
  {
    path: '/code',
    name: 'code',
    component: CodeView,
    meta: { autenticado: false },
  },
  {
    path: '/admin',
    component: AdminView,
    meta: { autenticado: true },
    children: [
    {
      path: 'UserTable',
      name: 'admin',
      component: UserTable,
    },
    {
      path: 'JornadasTable',
      name: 'JornadasTable',
      component: JornadasTable,
    }]
  },
  {
    path: '/*',
    redirect: '/',
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (!to.meta.autenticado) {
    next()
    return
  }
  //autenticacion con localstorage
  if (to.meta.autenticado && !!localStorage.getItem("token")) {
    if (to.name != "admin") {
      next()
      return
    }
    else {
      let base64Url = localStorage.getItem("token").split('.')[1]
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
      let token = JSON.parse(jsonPayload)
      if (token.dni == "admin") {
        next()
        return
      }
      else {
        next({ name: 'home' })
        return
      }
    }
  }
  //autenticacion con sessionstorage
  else if (to.meta.autenticado && !!sessionStorage.getItem("token")) {
    if (to.name != "admin") {
      next()
      return
    }
    else {
      let base64Url = sessionStorage.getItem("token").split('.')[1]
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
      let token = JSON.parse(jsonPayload)
      if (token.dni == "admin") {
        next()
        return
      }
      else {
        next({ name: 'home' })
        return
      }
    }
  }
  else {
    next({ name: 'login' })
    return
  }
})

export default router
