<template>
  <div>
    <v-banner
      outlined
      shaped
      class="mb-5 text-center"
      style="background-color: #90caf9"
    >
      Aquí puedes editar el tiempo de entrada/salida de cualquier jornada.
      <br />
    </v-banner>
    <v-snackbar
      v-model="snackbar"
      timeout="2500"
      :color="sbColor"
      top
      class="mt-16"
    >
      {{ msg }}
    </v-snackbar>
    <v-data-table
      :headers="headers"
      :items="jornadas"
      sort-by="dni"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Jornadas</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <json-excel
            :name="name"
            class="align-center v-btn theme--dark v-btn--has-bg v-btn--has-bg primary v-size--default "
            :data="jornadas"
          >
            Generar excel
          </json-excel>
          <v-dialog v-model="dialogEdit" max-width="500px">
            <v-card>
              <v-form ref="editForm">
                <v-card-title>
                  <span class="text-h5">Editar Jornada</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col md="6" cols="12">
                        <datePicker
                          class="px-0"
                          accion="entrada"
                          :inicial="newFechaEntrada"
                          @dateChange="(v) => (newFechaEntrada = v)"
                        />
                        <timePicker
                          accion="entrada"
                          :inicial="editedItem.entrada.substring(11, 19)"
                          @timeChange="(v) => (newHoraEntrada = v)"
                        />
                      </v-col>
                      <v-col md="6" cols="12">
                        <datePicker
                          class="px-0"
                          accion="salida"
                          :inicial="newFechaSalida"
                          @dateChange="(v) => (newFechaSalida = v)"
                        />
                        <timePicker
                          accion="salida"
                          :inicial="editedItem.salida.substring(11, 19)"
                          @timeChange="(v) => (newHoraSalida = v)"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="editClose">
                    Cancelar
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="editSave">
                    Guardar
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Recargar </v-btn>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import datePicker from "@/components/datePicker.vue";
import timePicker from "@/components/timePicker.vue";
import JsonExcel from "vue-json-excel";

export default {
  name: "JornadasTable",
  components: {
    datePicker,
    timePicker,
    JsonExcel,
  },
  data: () => ({
    name: ("0" + new Date().getDate()).slice(-2) + "-" + ("0" + (new Date().getMonth()+1)).slice(-2) + "-" + new Date().getFullYear(),
    id: "",
    entrada: "",
    salida: "",
    newFechaEntrada: "",
    newFechaSalida: "",
    newHoraEntrada: "",
    newHoraSalida: "",
    snackbar: false,
    msg: "",
    sbColor: "",
    dialogEdit: false,
    dialogAdd: false,
    headers: [
      { text: "DNI/NIE", value: "dni", align: "start" },
      { text: "Nombre", value: "nombre" },
      { text: "Apellidos", value: "apellidos" },
      { text: "Entrada [YYYY-MM-DD]", value: "entrada" },
      { text: "Salida [YYYY-MM-DD]", value: "salida" },
      { text: "", value: "actions", sortable: false },
    ],
    jornadas: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      dni: "",
      entrada: "",
      salida: "",
      nombre: "",
      apellidos: "",
    },
    defaultItem: {
      id: "",
      dni: "",
      nombre: "",
      apellidos: "",
    },
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.jornadas = [];
      axios({
        url: `${process.env.VUE_APP_API_URL}/getJornadas`,
        headers: { "Content-Type": "application/json" },
      })
        .then((result) => {
          for (let i = 0; i < result.data.length; i++) {
            this.jornadas.push({
              id: result.data[i][0],
              dni: result.data[i][1],
              nombre: result.data[i][4],
              apellidos: result.data[i][5],
              entrada: result.data[i][2],
              salida: result.data[i][3],
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    editItem(item) {
      this.id = item.id;
      this.editedIndex = this.jornadas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.newFechaEntrada = this.editedItem.entrada.substring(0, 10);
      this.newHoraEntrada = this.editedItem.entrada.substring(11, 19);
      this.newFechaSalida = this.editedItem.salida.substring(0, 10);
      this.newHoraSalida = this.editedItem.salida.substring(11, 19);
      this.dialogEdit = true;
    },
    editClose() {
      this.dialogEdit = false;
    },
    editSave() {
      this.editedItem.entrada =
        this.newFechaEntrada + " " + this.newHoraEntrada;
      this.editedItem.salida = this.newFechaSalida + " " + this.newHoraSalida;
      this.entrada = this.strToDate(this.editedItem.entrada);
      this.salida = this.strToDate(this.editedItem.salida);

      if (this.entrada == null || this.salida == null) {
        this.msg = "Todos los campos son obligatorios.";
        this.snackbar = false;
        this.snackbar = true;
        this.sbColor = "error";
      } else if (this.entrada > this.salida) {
        this.msg =
          "La fecha/hora de entrada no puede ser mayor que la de salida.";
        this.snackbar = false;
        this.snackbar = true;
        this.sbColor = "error";
      } else {
        const self = this;
        axios({
          method: "post",
          url: `${process.env.VUE_APP_API_URL}/editJornada`,
          data: {
            id: this.editedItem.id,
            entrada: this.newFechaEntrada + " " + this.newHoraEntrada,
            salida: this.newFechaSalida + " " + this.newHoraSalida,
          },
          headers: { "Content-Type": "application/json" },
        })
          .then((result) => {
            console.log(result);
            self.msg = "Jornada editada correctamente.";
            self.snackbar = false;
            self.snackbar = true;
            console.log(this.jornadas[this.editedIndex]);
            console.log(this.editedItem);
            Object.assign(this.jornadas[this.editedIndex], this.editedItem);
            self.sbColor = "success";
            return true;
          })
          .catch(function (error) {
            console.log(error);
            self.msg = "Ha ocurrido un error al editar la jornada.";
            self.snackbar = false;
            self.snackbar = true;
            self.sbColor = "error";
            return false;
          });
        this.editClose();
      }
    },
    strToDate: function (dtStr) {
      if (!dtStr) return null;
      let dateParts = dtStr.split("-");
      let timeParts = dateParts[2].split(" ")[1].split(":");
      dateParts[2] = dateParts[2].split(" ")[0];
      return new Date(
        +dateParts[2],
        dateParts[1] - 1,
        +dateParts[0],
        timeParts[0],
        timeParts[1],
        timeParts[2]
      );
    },
  },
};
</script>