<template>
  <v-menu
    ref="menu"
    v-model="picker"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="time"
    transition="scale-transition"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        ref="tf"
        v-model="time"
        :label="'Hora de ' + accion"
        prepend-icon="mdi-clock-time-four-outline"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-time-picker
      format="24hr"
      use-seconds
      v-if="picker"
      v-model="time"
      full-width
      @click:second="finalizar"
    ></v-time-picker>
  </v-menu>
</template>

<script>
export default {
  name: "timePicker",
  props: {
    accion: String,
    inicial: String,
  },
  data() {
    return {
      time: this.inicial,
      picker: false,
      modal: false,
    };
  },
  methods:{
    finalizar: function(){
      this.$refs.menu.save(this.time)
      this.$emit("timeChange", this.time)
    }
  }
};
</script>